import React, { Component } from 'react'
import './Footer.css'

class Footer extends Component {
  render() {
    return (
      <div className="bodyFooter">
        <h3 className="cr">Crédits</h3>
      </div>
    );
  }
}

export default Footer;